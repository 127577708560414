import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
// import ReceiptIcon from '@material-ui/icons/Receipt';
import PaymentIcon from '@material-ui/icons/Payment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import { Drawer, List, Divider, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStyles from '../../../pages/HomePage.style';
import { Context as AuthContext } from '../../../contexts/authContext';
import { useLanguageContext } from '../../../contexts/langContext';
import { UserRole } from '../../../types';

type SideBarProps = {
  onDrawerClose: () => void;
  isOpen: boolean;
};

export default function SideBar({ isOpen, onDrawerClose }: SideBarProps) {
  const [activeMenuItemName, setActiveMenuItemName] = useState('home');
  const classes = useStyles();
  const { t } = useTranslation();
  const { dir } = useLanguageContext();
  const { logout, user } = useContext(AuthContext);

  const onLogout = async () => {
    if (logout) {
      await logout();
      window.location.href = '/';
    }
  };

  return (
    <Drawer
      variant="permanent"
      anchor={dir === 'ltr' ? 'left' : 'right'}
      classes={{
        root: classnames(isOpen && classes.drawerRoot, !isOpen && classes.drawerPaperClose),
        paper: classnames(isOpen && classes.drawerPaper, !isOpen && classes.drawerPaperClose),
      }}
      open={isOpen}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={onDrawerClose}>
          <ChevronLeftIcon className={classes.icon} style={{ transform: isOpen ? 'none' : 'rotateY(180deg)' }} />
        </IconButton>
      </div>
      <Divider />
      <List>
        <div>
          <Link
            to="/"
            className={classes.link}
            style={{
              backgroundColor: activeMenuItemName === 'home' ? '#90caf9' : 'white',
              display: 'block',
            }}
            onClick={() => setActiveMenuItemName('home')}
          >
            <ListItem button>
              <ListItemIcon>
                <AssessmentIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={t('Claims')} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} />
            </ListItem>
          </Link>
          {user?.role !== UserRole.worker && (
            <Link
              to="/profile"
              className={classes.link}
              style={{
                backgroundColor: activeMenuItemName === 'profile' ? '#90caf9' : 'white',
                display: 'block',
              }}
              onClick={() => setActiveMenuItemName('profile')}
            >
              <ListItem button>
                <ListItemIcon>
                  <AccountBoxIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('Profile')} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} />
              </ListItem>
            </Link>
          )}
          {/* {user?.role !== UserRole.worker && (
            <Link
              to="/invoices"
              className={classes.link}
              style={{
                backgroundColor: activeMenuItemName === 'invoices' ? '#90caf9' : 'white',
                display: 'block',
              }}
              onClick={() => setActiveMenuItemName('invoices')}
            >
              <ListItem button>
                <ListItemIcon>
                  <ReceiptIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('Invoices')} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} />
              </ListItem>
            </Link>
          )} */}
          {user?.role !== UserRole.worker && (
            <Link
              to="/billing"
              className={classes.link}
              style={{
                backgroundColor: activeMenuItemName === 'billing' ? '#90caf9' : 'white',
                display: 'block',
              }}
              onClick={() => setActiveMenuItemName('billing')}
            >
              <ListItem button>
                <ListItemIcon>
                  <PaymentIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={t('Billing')} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} />
              </ListItem>
            </Link>
          )}
          <Link
            to="/support"
            className={classes.link}
            style={{
              backgroundColor: activeMenuItemName === 'support' ? '#90caf9' : 'white',
              display: 'block',
            }}
            onClick={() => setActiveMenuItemName('support')}
          >
            <ListItem button>
              <ListItemIcon>
                <HelpIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={t('Support')} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} />
            </ListItem>
          </Link>
          {user?.role === UserRole.superUser && (
            <>
              <Link
                to="/admin"
                className={classes.link}
                style={{
                  backgroundColor: activeMenuItemName === 'admin' ? '#90caf9' : 'white',
                  display: 'block',
                }}
                onClick={() => setActiveMenuItemName('admin')}
              >
                <ListItem button>
                  <ListItemIcon>
                    <AccountCircleIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary={t('Admin section')} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} />
                </ListItem>
              </Link>
              {/* <Link
                to="/edit"
                className={classes.link}
                style={{
                  backgroundColor: activeMenuItemName === 'edit' ? '#90caf9' : 'white',
                  display: 'block',
                }}
                onClick={() => setActiveMenuItemName('edit')}
              >
                <ListItem button>
                  <ListItemIcon>
                    <AccountCircleIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Edit" style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} />
                </ListItem>
              </Link> */}
            </>
          )}
          <ListItem button>
            <ListItemIcon>
              <ExitToAppIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary={t('Log out')} onClick={onLogout} style={{ textAlign: dir === 'rtl' ? 'right' : 'left' }} />
          </ListItem>
        </div>
      </List>
    </Drawer>
  );
}
