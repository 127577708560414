import { getBaseURL, getClient } from '../BLL/clients/HttpClient';
import { ApiEndPoint } from '../BLL/Enums/ApiEndPoint';
import { Member } from '../types';

let claims: Member[];
let lastRequestTime: number;
let totalInsuranceDays: number;
let totalInsuranceDaysUntilToday: number;

export const getAllMembers = async () => {
  if (claims && Date.now() - lastRequestTime < 1000 * 60) {
    return claims;
  }
  const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
  const client = getClient({ baseURL: authBaseUrl });
  const { data } = await client.get<{ members: Member[] }>('v1/stat/members');
  claims = data.members;
  lastRequestTime = Date.now();
  const res = data.members.map((x) => ({
    ...x,
    effectiveDate: new Date(x.effectiveDate),
    terminationDate: new Date(x.terminationDate),
    createdAt: new Date(x.createdAt),
    updatedAt: new Date(x.updatedAt),
    issuedDate: new Date(x.issuedDate),
    transactionDate: new Date(x.transactionDate),
  }));

  totalInsuranceDays = res.reduce((acc, member) => acc + calculateDaysBetweenDates(member.effectiveDate, member.terminationDate), 0);
  // total days of insurance until today calculated by issue date
  totalInsuranceDaysUntilToday = res
    .filter((x) => !isSameDayAsToday(x.issuedDate))
    .reduce((acc, member) => acc + calculateDaysBetweenDates(member.effectiveDate, member.terminationDate), 0);

  return res;
};

export function getMonthName(monthNumber: number) {
  const date = new Date(0, monthNumber - 1);
  return date.toLocaleString('default', { month: 'long' });
}

export type InsuranceDaysPerCarrier = {
  [carrierId: number]: {
    [yearMonth: string]: number;
  };
};

export function calculateDaysBetweenDates(startDate: Date, endDate: Date): number {
  const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
  return Math.round((endDate.getTime() - startDate.getTime()) / oneDay);
}

export function getInsuranceDaysPerCarrier(members: Member[]): InsuranceDaysPerCarrier {
  const result: InsuranceDaysPerCarrier = {};

  members.forEach((member) => {
    const { carrierId, effectiveDate, terminationDate } = member;
    const insuranceDays = calculateDaysBetweenDates(effectiveDate, terminationDate);

    const startYearMonth = `${effectiveDate.getFullYear()}-${String(effectiveDate.getMonth() + 1).padStart(2, '0')}`;
    const endYearMonth = `${terminationDate.getFullYear()}-${String(terminationDate.getMonth() + 1).padStart(2, '0')}`;

    if (!result[carrierId]) {
      result[carrierId] = {};
    }

    if (!result[carrierId][startYearMonth]) {
      result[carrierId][startYearMonth] = 0;
    }

    if (startYearMonth === endYearMonth) {
      result[carrierId][startYearMonth] += insuranceDays;
    } else {
      // Split the insurance days between the start and end months
      const daysInStartMonth = calculateDaysBetweenDates(effectiveDate, new Date(effectiveDate.getFullYear(), effectiveDate.getMonth() + 1, 0));
      const daysInEndMonth = calculateDaysBetweenDates(new Date(terminationDate.getFullYear(), terminationDate.getMonth(), 1), terminationDate);

      result[carrierId][startYearMonth] += daysInStartMonth;
      if (!result[carrierId][endYearMonth]) {
        result[carrierId][endYearMonth] = 0;
      }
      result[carrierId][endYearMonth] += daysInEndMonth;
    }
  });

  return result;
}

type TotalRevenuePerDayResults = {
  carrierId: number;
  issueDate: string;
  insuranceDays: number;
  amountPerDay: number;
  totalRevenue: number;
};

type CarrierId = string;

const ONE_MILLION = 1000000;

export const calculateTotalRevenuePerDay = (members: Member[]): TotalRevenuePerDayResults[] => {
  let totalDays = totalInsuranceDaysUntilToday;
  const membersFromToday = members.filter((x) => x.status === 'active' && isSameDayAsToday(x.issuedDate));
  const res = membersFromToday.reduce<Record<CarrierId, TotalRevenuePerDayResults>>((carriersMembersToday, currentMember) => {
    const issueDate = `${currentMember.issuedDate.getFullYear()}-${String(currentMember.issuedDate.getMonth() + 1).padStart(
      2,
      '0'
    )}-${currentMember.issuedDate.getDate()}`;
    const key = `${currentMember.carrierId}_${issueDate}`;
    if (!carriersMembersToday[key]) {
      carriersMembersToday[key] = {
        carrierId: currentMember.carrierId,
        issueDate,
        insuranceDays: 0,
        amountPerDay: 1,
        totalRevenue: 0,
      };
    }
    carriersMembersToday[key].insuranceDays += calculateDaysBetweenDates(currentMember.effectiveDate, currentMember.terminationDate);
    totalDays += carriersMembersToday[key].insuranceDays;
    carriersMembersToday[key].totalRevenue = calculateRevenue(carriersMembersToday[key].insuranceDays, totalDays);

    return carriersMembersToday;
  }, {});

  return Object.values(res);
};

export const calculateTotalRevenuePerMonth = (members: Member[]): TotalRevenuePerDayResults[] => {
  let totalDays = totalInsuranceDaysUntilToday;
  const membersFromToday = members.filter((x) => x.status === 'active' && isSameMonthAsToday(x.issuedDate));
  const res = membersFromToday.reduce<Record<CarrierId, TotalRevenuePerDayResults>>((carriersMembersToday, currentMember) => {
    const issueDate = `${currentMember.issuedDate.getFullYear()}-${String(currentMember.issuedDate.getMonth() + 1).padStart(2, '0')}`;
    const key = `${currentMember.carrierId}_${issueDate}`;
    if (!carriersMembersToday[key]) {
      carriersMembersToday[key] = {
        carrierId: currentMember.carrierId,
        issueDate,
        insuranceDays: 0,
        amountPerDay: 1,
        totalRevenue: 0,
      };
    }
    carriersMembersToday[key].insuranceDays += calculateDaysBetweenDates(currentMember.effectiveDate, currentMember.terminationDate);
    totalDays += carriersMembersToday[key].insuranceDays;
    carriersMembersToday[key].totalRevenue = calculateRevenue(carriersMembersToday[key].insuranceDays, totalDays);

    return carriersMembersToday;
  }, {});

  return Object.values(res);
};

const calculateRevenue = (insuranceDays: number, totalDays: number) => {
  let res = 0;
  if (totalDays < ONE_MILLION * 2.5) {
    res = insuranceDays * 0.4;
  } else if (totalDays > ONE_MILLION * 2.5 && totalDays < ONE_MILLION * 5) {
    res = insuranceDays * 0.45;
  } else if (totalDays > ONE_MILLION * 5) {
    res = insuranceDays * 0.5;
  }
  return Number(res.toFixed(2));
}

const isSameDayAsToday = (date: Date) => {
  const today = new Date();
  return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate();
};

const isSameMonthAsToday = (date: Date) => {
  const today = new Date();
  return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth();
};

const isSameQuarterAsToday = (date: Date) => {
  const today = new Date();
  return date.getFullYear() === today.getFullYear() && Math.floor(date.getMonth() / 3) === Math.floor(today.getMonth() / 3);
};
